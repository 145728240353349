<template>
  <cs-row  class="cs-element-event is-multiline typo">
    <cs-col class="col1">
      <strong class="date">{{ event.date | date }}</strong>
      <span v-if="event.time">{{ event.time | time }}</span>
    </cs-col>
    <cs-col class="col2">
      <strong class="town">
        {{ event.town }}
      </strong>
      <strong class="title">
        {{ event.title }}
      </strong>
      <span class="venue">
        {{ event.venue }}
      </span>
    </cs-col>
    <cs-col class="col3">
      <strong class="title">
        {{ event.title }}
      </strong>
      <span v-if="event.address" class="address">
        {{ event.address }}
      </span>
      <span class="venue">
        {{ event.venue }}<template v-if="event.address">, {{ event.address }}</template>
      </span>
      <cs-text :content="event.info" class="info" />
    </cs-col>
    <cs-col class="col4">
      <template v-if="event.canceled">
        <span class="is-warning">
          {{ $t('event.canceled') }}
        </span>
      </template>
      <template v-else>
        <span v-if="event.tickets === 'charge'">
          {{ $t('event.tickets') }}:
          {{ event.pricefrom | number(2, true) }}<template v-if="event.priceto > 0">–{{ event.priceto | number(2, true) }}</template> EUR
        </span>
        <span
          v-if="event.tickets === 'na'"
          class="is-comment">
            {{ $t('event.tickets') }}: {{ $t('event.na') }}
        </span>
        <span v-if="event.tickets === 'free'">
          {{ $t('event.free') }}
        </span>
        <span
          v-if="event.tickets === 'sold'"
          class="is-warning">
            {{ $t('event.sold') }}
        </span>
        <span v-if="event.tickets === 'private'">
          {{ $t('event.private') }}
        </span>
        <div v-if="hasButtons" class="buttons">
          <cs-link v-if="event.infourl.value" :link="event.infourl">
            <cs-button class="is-text is-primary" icon="info">{{ $t('event.info') }}</cs-button>
          </cs-link>
          <cs-link v-if="event.presales && event.presaleslink.value" :link="event.presaleslink">
            <cs-button class="is-text is-primary" icon="ticket">{{ $t('event.presales') }}</cs-button>
          </cs-link>
          <cs-button v-if="event.boxoffice" class="is-text is-disabled">{{ $t('event.boxoffice') }}</cs-button>
        </div>
      </template>
    </cs-col>
  </cs-row>
</template>

<script>

export default {
  name: 'cs-element-event',
  components: {},
  props: {
    event: Object
  },
  data() {
    return {}
  },
  created () {},
  mounted () {},
  computed: {
    hasButtons () {
      return this.event.infourl.value || this.event.presales || this.event.boxoffice
    }
  },
  methods: {},
  events: {}
}
</script>

<style lang="sass">

// view-1
.cs-element-event
  margin-bottom: m(3)
  .cs-col
    display: flex
    flex-direction: column
    &.col1
      width: 12%
    &.col2
      width: 20%
      .title
        display: none
    &.col3
      width: 46%
      .venue
        display: none
      .info
        padding-top: m(2)
        p
          color: grey(4)
    &.col4
      flex-direction: column
      justify-content: flex-start
      width: 22%
      .is-warning
        +font('semibold')
        color: color('warning')
      .is-comment
        color: grey(6)
      .buttons
        display: flex
        flex-align: center
        padding-top: 3px
        >.cs-button,
        >.cs-link
          margin: 0 m(1)
          &:first-child
            margin-left: 0
          &:last-child
            margin-right: 0

// view-2
+until(1200px)
  .cs-element-event
    .cs-col
      &.col1
        width: 18%
        padding-bottom: 0
      &.col2
        width: 24%
        padding-bottom: 0
      &.col3
        width: 58%
        padding-bottom: 0
      &.col4
        width: 58%
        margin-left: 42%
        padding-top: 0

// view-3
+until(900px)
  .cs-element-event
    .cs-col
      &.col1
        width: 22%
        .venue
          display: none
      &.col2
        width: 78%
        .title
          display: inline
        .venue
          display: none
      &.col3
        width: 78%
        margin-left: 22%
        padding-top: 0
        .venue
          display: inline
        .title
          display: none
        .address
          display: none
      &.col4
        width: 78%
        margin-left: 22%

// view-4
+until(600px)
  .cs-element-event
    .cs-col
      &.col1
        width: 100%
        display: flex
        flex-direction: row
        padding-bottom: 0
        .date
          margin-right: m(1)
      &.col2
        width: 100%
        padding-top: 0
        .town
          display: none
      &.col3
        width: 100%
        margin-left: 0
        padding-top: 0
      &.col4
        width: 100%
        margin-left: 0

</style>